import { useModalControls } from '@/providers/modal';
import type { FormikConfig, FormikProps } from 'formik';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import type { ReactNode } from 'react';

export type FormProps<T> = {
	success?: ReactNode,
	autoSave?: boolean,
	noClose?: boolean,
	children?: ( ( props: FormikProps<T> & { isSaving?: boolean } ) => ReactNode ) | ReactNode
} & FormikConfig<T>;

export default function Form<T>( { children, onSubmit, success, autoSave, noClose, ...props }: FormProps<T> ) {
	const { enqueueSnackbar } = useSnackbar();
	const { closeModal } = useModalControls();
	
	return (
		<Formik
			{...props}
			onSubmit={async ( values, formikHelpers ) => {
				try {
					await onSubmit( values, formikHelpers );
					if ( success ) enqueueSnackbar( success, { variant: 'success' } );
					if ( !noClose ) closeModal();
				} catch ( e ) {
					enqueueSnackbar( e?.response?.data || e?.message || e, { variant: 'error' } );
				}
			}}>
			{children}
		</Formik>
	);
}
