import RichTextField from '@/components/tiptap/richTextField';
import { mutateGraphQL } from '@/data';
import { PolicyRead, PolicyWrite } from '@/data/management/policy.graphql';
import FormGraphqlProvider from '@/data/query/formGraphqlProvider';
import type { MutationPolicyWriteArgs, Policy } from '@/types/schema';
import { Theme, useMediaQuery } from '@mui/material';
import type { ComponentType, ReactNode } from 'react';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

export default function PolicyInputModal( {
	id,
	onSubmit,
	Wrapper = Fragment,
}: {
	id: string,
	onSubmit?: ( policy: Policy ) => void,
	Wrapper: ComponentType<{ name: string, secondaryTitle?: string, children: ReactNode }>
} ) {
	const { t } = useTranslation();
	const isMobile = useMediaQuery<Theme>( ( { breakpoints } ) => breakpoints.down( 'sm' ) );
	
	return (
		<FormGraphqlProvider<Policy>
			id={id}
			queryKey='policy'
			query={PolicyRead}
			onSubmit={async ( { ...values } ) => {
				const { policyWrite } = await mutateGraphQL<MutationPolicyWriteArgs>( {
					mutation : PolicyWrite,
					variables: {
						id,
						input: { policy: values.policy || '' },
					},
				} );
				onSubmit?.( policyWrite );
			}}>
			<Wrapper
				name={t( 'common:policy' )}
				secondaryTitle={t( 'common:policy-des' )}>
				<RichTextField
					hasBorder={!isMobile}
					name='policy'
					placeholder='Type your policy here...'
					minHeight={500}
				/>
			</Wrapper>
		</FormGraphqlProvider>
	);
}
